@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700&display=swap);
@font-face {
  font-family: 'Love';
  src:  url(/static/media/A-Love-of-Thunder.59efaeb2.ttf) format('truetype')
}

@font-face {
  font-family: 'Minion';
  src:  url(/static/media/MinionPro-Regular.38711b5b.otf) format('opentype')
}

@font-face {
  font-family: 'Proxima';
  src:  url(/static/media/ProximaNova-Regular.410504d4.otf) format('opentype')
}

@font-face {
  font-family: 'Proxima-Bold';
  src:  url(/static/media/Proxima-Nova-Bold.62d4d7d3.otf) format('opentype')
}

@font-face {
  font-family: 'Proxima-ExtraBold';
  src:  url(/static/media/Proxima-Nova-Extrabold.b4f9eb8c.otf) format('opentype')
}


html,
body {
  width: 100%;
  height: 100%;
  color: white;
  background-size: cover;
  background-repeat: repeat-y;
  font-family: 'Proxima', sans-serif;
  background-image: url(/static/media/background.0c2de5b5.png);
}

h1, h2, h3, h4, h5 {
  font-family: 'Proxima-Bold', sans-serif;
}

.pt-anchor {
  padding-top: 6rem;
}

.navbar {
  padding: 0;
  box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.06);
  background: #fff !important;
}

.navbar img {
  height: 60px;
}

a.nav-link {
  font-size: medium;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Proxima-ExtraBold', sans-serif;
  letter-spacing: 1px;
}

.carousel-item {
  padding: 5% 5% 20px;
}

.paragraph-text {
  color: white;
  font-size: 2rem;
  padding: 0 10%;
  text-align: center;
  margin-bottom: 25px;
  text-shadow: 1px 1px gray;
}

.galery-item-image{
  position: relative;
}

.galery-item-button {
  position: absolute;
  bottom: 30px;
}

.btn-galery-item {
  background-color: white;
}

.btn-galery-item a {
  color: rgb(80, 80, 80);
}

.btn-galery-item a:hover {
  color: rgb(80, 80, 80);
  text-decoration: none !important;
}

.clip-header {
  letter-spacing: 2px;
}

.clip-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.clip-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.lesson-wrapper {
  background-color: white;
  color: black;
  padding: 75px 50px;
  font-size: x-large;    
  border-right: 2px solid #e9ecef;
}

.slide-text {
  font-size: larger;
}

.package-wrapper {
  background-color: white;
  color: black;
  padding: 75px 50px;
  font-size: x-large;
  text-align: justify;
}

.about {
  margin: 30px 0 5px;
  font-size: medium;
  line-height: 1.7;
  font-weight: 600;
  color: #777;
}

figure {
  margin-top: 25px;
  margin-bottom: 25px;
}

figure:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

figcaption.figure-caption.text-center {
  margin-top: -33px;
  color: black;
  font-size: larger;
  font-weight: bold;
  background-color: #F8F9FA;
  opacity: 0.6;
  position: relative;
}


.image-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer {
  color: white;
  position: relative;
  text-align: center;
  padding: 10px 0;
  background: #222222;
  font-weight: 500;
}

.dot {
  margin: 5px;
  width: 15px;
  height: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot-active {
  background-color: black;
}

.reservation-wrapper {
  display: flex;
  background-color: white;
  justify-content: center; 
  align-items: center;
  border-right: 2px solid #e9ecef;
}

.contact-detail {
  font-size: small;
  font-style: oblique;
}


footer .row .col-md-4 {
  padding-left: 30px;
  margin-bottom: 30px;
}

.social-network a {
  color: #fff;
}

.social-network a:visited {
  color: #e0e0e0
}

.carousel-control-next, .carousel-control-prev {
  width: 5% !important;
}


@media (max-width: 575.98px) {
  .image-bottom {
    flex-direction: column !important;
  }
  
}

@media (max-width: 767.98px) {
  .navbar img {
    height: 45px;
  }

  footer h3 {
    font-size: 1.2rem;
  }

  footer h5 {
    font-size: 1rem;
  }

  footer p, .contact-detail {
    font-size: .7rem;
  }

}


@media (max-width: 991.98px) { 
  .image-bottom {
    align-items: center;
    flex-direction: row;
  }

  .image-bottom .image-item {
    margin-bottom: 25px;
  }
}

@media (min-width: 992px){
  .image-bottom .image-item {
    width: 56%!important;
  }
}

